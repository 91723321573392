import { AgGridReact } from "ag-grid-react";
import { useRegionConfig } from "in_queue/contexts/ClusterContext";
import { useCurrentProjectId } from "in_queue/contexts/ScenarioPageContext";
import { useScenarioResults } from "in_queue/contexts/ScenarioResultsContext";
import { InQueueRegionConfig } from "in_queue/types/configPerRegion";
import { ScenarioAllocatedUpgrade } from "in_queue/types/scenarioResultType";
import { useRef, useState } from "react";
import { mapLoadingState } from "types/loadingType";
import {
    AgGridColumnDefs,
    AllocatedUpgradesTableColumnId,
    constraintTypeFormatter,
    currencyFormatter,
    dfaxFormatter,
    hideUnwantedColumns,
    roundingFormatter
} from "./agGrid/agGridHelpers";
import { BaseAgGrid } from "./base/BaseAgGrid";
import { ExportButton } from "./ribbon/ExportButton";
import { TableActionRibbon } from "./ribbon/TableActionRibbon";
import { ViewType, ViewTypesMenu } from "./ribbon/ViewTypesMenu";
import css from "./TableStyles.module.scss";

export const ScenarioAllocatedUpgradesTable: React.FC<{
    filterText: string;
}> = ({ filterText }) => {
    const gridRef = useRef<AgGridReact<ScenarioAllocatedUpgrade>>(null);
    const scenarioResults = useScenarioResults();
    const componentConfig = useRegionConfig();
    const currentProjectId = useCurrentProjectId();
    const [viewType, setViewType] = useState<ViewType>("current");
    const leftActions = [
        <ViewTypesMenu
            currentViewType={viewType}
            setCurrentViewType={setViewType}
            validViewTypes={["current", "related", "all"]}
        />
    ];
    const rightActions = [
        <ExportButton gridRef={gridRef} exportName="Assigned upgrades" />
    ];

    const upgrades = mapLoadingState(
        scenarioResults,
        (d) => d.allocatedUpgrades
    );

    return (
        <div className={css["table-ribbon-wrapper"]}>
            <TableActionRibbon
                leftActions={leftActions}
                rightActions={rightActions}
            />
            <BaseAgGrid<ScenarioAllocatedUpgrade>
                ref={gridRef}
                loadingData={upgrades}
                columnDefs={getColumnDefs(componentConfig)}
                quickFilterText={filterText}
                isExternalFilterPresent={() => viewType !== "all"}
                doesExternalFilterPass={(node) => {
                    if (
                        viewType === "related" &&
                        scenarioResults !== "loading"
                    ) {
                        const relatedProjectIds =
                            scenarioResults.relatedProjectIds;
                        return (
                            node.data != null &&
                            relatedProjectIds.has(node.data.projectId)
                        );
                    } else {
                        return node.data?.projectId === currentProjectId;
                    }
                }}
            />
        </div>
    );
};

const getColumnDefs = (config: InQueueRegionConfig) => {
    const columnDefs: AgGridColumnDefs<
        ScenarioAllocatedUpgrade,
        AllocatedUpgradesTableColumnId
    > = [
        {
            colId: "projectId",
            field: "projectId",
            headerName: "Project",
            width: 120,
            sort: "asc",
            pinned: true,
            filter: true
        },
        {
            colId: "networkUpgradeId",
            field: "networkUpgradeId",
            headerName: "Network upgrade ID",
            width: 120,
            pinned: true,
            filter: true,
            wrapHeaderText: true
        },
        {
            colId: "networkUpgradeName",
            field: "networkUpgradeName",
            headerName: "Network upgrade",
            pinned: true,
            filter: true
        },
        {
            colId: "constraintType",
            field: "constraintType",
            headerName: "Type",
            width: 80,
            filter: true,
            valueFormatter: constraintTypeFormatter
        },
        {
            headerName: "Cost calculation",
            children: [
                {
                    colId: "allocatedCost",
                    field: "allocatedCost",
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    width: 120,
                    sort: "desc",
                    valueFormatter: currencyFormatter
                },
                {
                    colId: "totalCost",
                    field: "totalCost",
                    width: 100,
                    valueFormatter: currencyFormatter
                },
                {
                    colId: "percentAllocated",
                    field: "percentAllocated",
                    width: 100,
                    valueFormatter: dfaxFormatter
                },
                {
                    colId: "maxDfax",
                    field: "maxDfax",
                    headerName: config.maxDfaxLabel,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    width: 100,
                    valueFormatter: dfaxFormatter
                },
                {
                    colId: "sumMwImpact",
                    field: "sumMwImpact",
                    headerName: "Sum MW Impact",
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    width: 100,
                    valueFormatter: roundingFormatter
                }
            ]
        },
        {
            colId: "facilityNames",
            field: "monitoredFacilityNames",
            headerName: "Monitored facilities",
            width: 500
        },
        {
            colId: "notes",
            field: "notes",
            headerName: "Notes",
            autoHeight: true,
            wrapText: true,
            width: 300
        }
    ];

    hideUnwantedColumns(
        columnDefs,
        config.allocatedUpgradesTable.columnsToRemove
    );

    return columnDefs;
};
